import React, { useEffect, useState } from 'react';
import { useNavigate , Link } from 'react-router-dom';
import './LoginPage.css';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import { ReactComponent as MailSend } from '../../demoImages/Login/MailSend.svg';
import styled from 'styled-components';
import Loading from '../Loading/Loading';

function LoginPage( {setProfileImage,setLoggedIn, setPhoneNumber ,setAppIsLoading}) {
  const [isLiked, setIsLiked] = useState(false);
  const [email, setEmail] = useState(''); // State variable for email
  const [password, setPassword] = useState(''); // State variable for password
  const [isEmailEntered, setIsEmailEntered] = useState(false);
  const [name, setName] = useState('');
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [sendEmail,setSendEmail] = useState(false);
  const [isFocused, setIsFocused] = useState(false);


  const login = async () => {
    try {
      const FormData = require('form-data');
      const data = new FormData();
      data.append('email', email);
      data.append('password', password);

      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://backend.monkeytrade.xyz/api/login',
        headers: {
          'Content-Type': 'multipart/form-data' // Set content type explicitly
        },
        data: data
      };

      const response = await axios.request(config);
      setLoggedIn(true);
      setAppIsLoading(false);
      localStorage.setItem("bearerToken", response.data.authorisation.token);
      setIsLoading(false);
      localStorage.setItem("userName",name)
      setProfileImage(response.data.user.profile_picture);
      setPhoneNumber(response.data.user.phone)
      navigate('/acc/home');
    } catch (error) {
      setIsEmailEntered(false)
      console.log(error);
    }
  };

  const verifyEmail = async () => {
    try {
      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `https://backend.monkeytrade.xyz/api/verify_email?email=${email}`
      };

      const response = await axios.request(config);
      setName(response.data.user_name)
      setIsEmailEntered(true);
      setIsLoading(false)
    } catch (error) {
      console.log(error);
      if(error.response.status===404){
        localStorage.setItem("newUserEmail",email)
        setSendEmail(true)
        setIsLoading(false)
      }
    }
  };

  

  const handleLike = () => {
    setIsLiked(!isLiked);
  };

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleEmailSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true)
    verifyEmail()
  };

  const handlePasswordSubmit = (event) => {
    setIsLoading(true)
    event.preventDefault();
    login();
    setEmail('');
    setPassword('');
  };

  		
  const handleForgotPasswordClick = () => {
    //setForgotPasswordClicked(true);
    // Here you can add logic to handle forgot password functionality
  };

  const handleResendClick = () => {
   // navigate('/path-to-navigate');
   console.log("Navigate to create page..!")
  };


  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    if (!email) {
      setIsFocused(false);
    }
  };

  return (
    <>
    { isLoading&&<Loading/>}
    <div style={{justifyItems:'center'}}>
      <div className="login-page-container">
        <h1> Monkey Trade </h1>

        
            <div>
            <div className="email-banner">
              {isEmailEntered&&<div className='password-welcome'>Hi {name}</div>}
              <div className={!sendEmail?isEmailEntered?'password-heading':'email-heading':'verify-email-heading'}>{sendEmail?'Verify Your Email':isEmailEntered?'Enter Your Password':'Enter Your Email ID'}</div>
              {sendEmail?
              <div className='verify-email'>
              <div className='verify-email-message'>
                <div className='verify-email-subhead1'>
                Please check your email for a link to verify your email address.
                </div>
                <div className='verify-email-subhead2'>
                Once verified you will be able to continue.
                </div>
                </div>
                <MailSend/>
                <div className='verify-email-subhead1'>
                Didn’t receive an email? <a href="https://google.com/">Resend</a>
                </div>
              </div>
              :
              isEmailEntered?
              <form onSubmit={handlePasswordSubmit}>
                <div className='password-input-header'>Enter your password for {email} </div>
                <div className='password-container'>
                  <div className="input-wrapper">
                  <input
                    type="password"
                    id="password"
                    placeholder={isFocused ? '' : ' '}
                    value={password}
                    onChange={handleChangePassword}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                  <div className={`placeholder ${isFocused || email ? 'hidden' : ''}`} id="placeholder">
                    <span className="red">*</span> Required Information
                  </div>
                </div>
                </div>
                <div className='button-container'>
                  <button type="submit"> LOG IN </button>
                </div>
                
              </form>
              :
              <form onSubmit={handleEmailSubmit}>
                <div className='email-input-header'> Username </div>
                <div className='email-container'>
                <div className="input-wrapper">
                  <input
                    type="email"
                    id="email"
                    placeholder={isFocused ? '' : ' '}
                    value={email}
                    onChange={handleChangeEmail}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                  <div className={`placeholder ${isFocused || email ? 'hidden' : ''}`} id="placeholder">
                    <span className="red">*</span> Required Information
                  </div>
                </div>
                </div>
                <div className='button-container'>
                  <button type="submit"> SEND </button>
                </div>
                
              </form>}
          </div>
        </div>
        <div className='footer'>
          <p>By clicking “Log in”, you agree with our <terms style={{textDecorationLine: 'underline'}}>Terms</terms>. Learn how we process your data in our <privacy style={{textDecorationLine: 'underline'}}>Privacy Policy</privacy> and <cookies style={{textDecorationLine: 'underline'}}>Cookies Policy</cookies>.</p>
        </div>
      </div>
    </div>
    </>
  );
};

export default LoginPage;
