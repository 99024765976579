import React,{useState} from 'react';
import './ItemTile.css';
import { FaEdit  } from 'react-icons/fa';
import { MdDelete } from "react-icons/md";


const ItemTile = ({ item, onDelete, onEdit }) => {

    const [isHovered,setIsHovered] = useState(true)
  return (
    <div className="item-tile">
      <div className="item-tile-left-section">
      <div className="item-tile-image-container" 
            onMouseEnter={() => setIsHovered(true)} 
            onMouseLeave={() => setIsHovered(false)}
            style={{ backgroundImage: `url(${item.product_image})` }}>
            <div className="tile-overlay"></div>
            {isHovered && (
                <div className="item-tile-close-button" onClick={() => onDelete(item.id)}><MdDelete/></div>
            )}
        </div>
      </div>
      <div className="item-tile-right-section">
        <div className="item-tile-details">
          <div className="item-tile-item-name">{item.title}</div>
          <div className="item-description">{item.description}</div>
        </div>
        <div className="item-tile-edit-icon" onClick={() => onEdit(item.id)}><FaEdit/></div>
      </div>
    </div>
  );
};

export default ItemTile;
