import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Slider, MenuItem, Autocomplete, Chip } from '@mui/material';
import IconButton from '@material-ui/core/IconButton';
import {DatePicker,LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import axios from 'axios';
import './AddItem.css'
import Loading from '../Loading/Loading';
import { RiUpload2Line } from 'react-icons/ri';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import MyLocation from '@mui/icons-material/MyLocation';
const categories = ['Category 1', 'Category 2', 'Category 3'];

const AddItem = ({loadProfile,setLoadProfile,setItemIndex}) => {
  const navigate = useNavigate();
  const [isLoading,setIsLoading] = useState(true);
  const [prodCategoryList,setProdCategoryList] = useState(categories)
  const [images, setImages] = useState([]);
  const [locationLabel, setLocationLabel] = useState("Search Location");
  const [marks,setMarks] = useState([{value:0,label:'0$'},{value:10000,label:'10000$'}])
  const [wishlist,setWishlist] = useState([]);
  const [selectedYear,setSelectedYear] = useState('')
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    category_id: '',
    year: '',
    lower_price_range: 0,
    higher_price_range:10000,
    images: [],
    latitude: '',
    longitude: '',
    tags:[]
  });
  const [locationOptions, setLocationOptions] = useState([]);
  const [optionsToShow, setOptionsToShow] = useState([]);

  useEffect(()=>{
    console.log(selectedYear)
  },[selectedYear])

  useEffect(()=>{
    axios.get('https://backend.monkeytrade.xyz/api/product-categories', {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('bearerToken')}`
  }
})
  .then(response => {
    console.log('Data:', response.data);
    setProdCategoryList(response.data.data)
    axios.get('https://backend.monkeytrade.xyz/api/wish_list', {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('bearerToken')}`
  }
})
  .then(response => {
    console.log('Data:', response.data);
    setWishlist(response.data.data)
    setIsLoading(false)
  })
  .catch(error => {
    // Handle error
    console.error('Error fetching data:', error);
  });


  })
  .catch(error => {
    // Handle error
    console.error('Error fetching data:', error);
  });
  },[])

  useEffect(()=>{
    console.log("Data",formData)
  },[formData])

  const handleImageUpload = (event) => {
    if (images.length < 6) {
      const file = event.target.files[0];
      setImages([...images, URL.createObjectURL(file)]);
      const updatedImages = [...formData.images, file];
      setFormData({ ...formData, images: updatedImages });
    }
  };

  const handleRemoveImage = (index) => {
    const newImages = images.filter((_, imgIndex) => imgIndex !== index);
    setImages(newImages);

    const newImageFiles = formData.images.filter((_, imgIndex) => imgIndex !== index);
    setFormData({ ...formData, images: newImageFiles });
  };



  const handleSliderChange = (event, newValue) => {
    setFormData({ ...formData, lower_price_range : newValue[0],higher_price_range :newValue[1] });
  };

  const handleLocationSearch = (query) => {
    axios.get(`https://nominatim.openstreetmap.org/search?q=${query}&format=json&addressdetails=1`)
      .then(response => {
        setLocationOptions(response.data);
      })
      .catch(error => {
        console.error("There was an error searching the location!", error);
      });
  };

  const handleLocationSelect = (event, value) => {
    setFormData({ ...formData, latitude: value ? value.lat : '' , longitude: value ? value.lon : '' });
  };

  const handleLocateMe = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        axios.get(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`)
          .then(response => {
            const address = response.data.display_name;
            setLocationLabel(address);
            setFormData({ ...formData, latitude: latitude.toString(), longitude:  longitude.toString()});
          })
          .catch(error => {
            console.error("There was an error retrieving the location!", error);
          });
      },
      (error) => {
        console.error("Error retrieving location:", error);
      }
    );
  };


  const handleTagInputChange = (event, newValue) => {
    const tagIds = newValue.map(tagName => {
      const tagObj = wishlist.find(tag => tag.tag === tagName);
      return tagObj ? tagObj.id : null;
    }).filter(id => id !== null); // Filter out any null values in case tagObj was not found
  
    // Update the formData state with the new list of tag IDs
    setFormData({ ...formData, tags: tagIds });
  };

  const toggleLoading =()=>{
    setIsLoading(!isLoading);
  }
  const handleSubmit = async (event) => {
    toggleLoading()
    event.preventDefault();
    const formDataToSend = new FormData();
    formDataToSend.append("title", formData.title);
    formDataToSend.append("description", formData.description);
    formDataToSend.append("category_id", formData.category_id);
    // formDataToSend.append("year", selectedYear.toString());
    formDataToSend.append("lower_price_range", formData.lower_price_range);
    formDataToSend.append("higher_price_range", formData.higher_price_range);
    formDataToSend.append("latitude", formData.latitude);
    formDataToSend.append("longitude", formData.longitude);
    formDataToSend.append("tags", JSON.stringify(formData.tags));    // formDataToSend.append("images[]",formData.images);
    formData.images.forEach((image, index) => {
      const key = "images" + (index + 1); // Create the key dynamically
      formDataToSend.append(key, image);  // Append the image with the dynamic key
    });

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://backend.monkeytrade.xyz/api/add_product',
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${localStorage.getItem('bearerToken')}`,
      },
      data: formDataToSend,
      redirect: "follow"
    };

    try {
      const response = await axios.request(config);
      console.log(response.data)
      setIsLoading(false);
      navigate('/acc/home')
      setItemIndex(0);
      setLoadProfile(loadProfile+1)
      
    } catch (error) {
      console.log(error);
    }
  };

  const getTopTags = () => {
    return wishlist.slice(0, 10); // Return the first 10 tags
  };
  const [inputValue, setInputValue] = useState('');
  const handleInputChange = (event, newInputValue) => {
    if(newInputValue!=""){
    setInputValue(newInputValue);
    const filteredOptions = wishlist.filter(tag => tag.tag.toLowerCase().includes(inputValue.toLowerCase())).slice(0, 10)
      .map(tag => tag.tag);

    setOptionsToShow(filteredOptions);
  }
    else {
      setInputValue(newInputValue);
      setOptionsToShow([])
    }
  };

  const filteredTags = wishlist.filter(tag => tag.tag.toLowerCase().includes(inputValue.toLowerCase())).slice(0, 10);

  return (
    <>
    {isLoading?<Loading/>:
    <div className='add-items-container'>
      <h2>Add Items</h2>
      <div className="container">
      <div className="left-section">
      <div className="image-upload-container">
        {images.map((image, index) => (
          <div key={index} className="uploaded-image-wrapper">
            <img src={image} alt={`upload-${index}`} className="uploaded-image" />
            <button className="close-button" onClick={() => handleRemoveImage(index)}>✖</button>
          </div>
        ))}
        {images.length < 6 && (
          <div className="image-upload-box">
            <input type="file" accept="image/*" onChange={handleImageUpload} className="file-input" />
            <div className="upload-icon"><RiUpload2Line size={30} /></div>
          </div>
        )}
      </div>
    </div>
        <div className="right-section">
          <form onSubmit={handleSubmit}>
            <TextField
              label="Title"
              variant="outlined"
              fullWidth
              margin="normal"
              style={{ borderRadius: '10px', backdropFilter: 'blur(2px)', width:'450px' }}
              onChange={(e) => setFormData({ ...formData, title: e.target.value })}
            />
            <TextField
              label="Category"
              select
              fullWidth
              margin="normal"
              style={{ borderRadius: '10px', backdropFilter: 'blur(2px)' }}
              onChange={(e) => setFormData({ ...formData, category_id: e.target.value })}
            >
              {prodCategoryList.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.title}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label="Description"
              variant="outlined"
              multiline
              rows={3}
              fullWidth
              margin="normal"
              style={{ borderRadius: '10px', backdropFilter: 'blur(2px)' }}
              onChange={(e) => setFormData({ ...formData, description: e.target.value })}
            />
            
             <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                views={['year']}
                label="Year"
                value={formData.year || null}
                onChange={(newValue) => {
                  setSelectedYear(newValue.toJSON().toString().slice(0,4))
                }}
                renderInput={(params) => <TextField {...params} />}
                fullWidth
                margin="normal"
                style={{ borderRadius: '10px', backdropFilter: 'blur(2px)' }}
                />
              </LocalizationProvider>
            <div className="slider-container">
              <label>Price Range</label>
              <Slider
                value={[formData.lower_price_range,formData.higher_price_range]}
                onChange={handleSliderChange}
                valueLabelDisplay="auto"
                min={0}
                max={10000}
                marks={marks}
                style={{ borderRadius: '10px', backdropFilter: 'blur(2px)' }}
              />
            </div>
            <Autocomplete
              multiple
              id="tags-filled"
              noOptionsText="Type your wishlist"
              options={optionsToShow} // Use only tag names for options
              onChange={handleTagInputChange}
              inputValue={inputValue}
              onInputChange={handleInputChange}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    key={index}
                    label={option}
                    {...getTagProps({ index })}
                    style={{ backgroundColor: '#f0f0f0', marginRight: 5 }}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Trade Wishlist's Tags"
                  margin="normal"
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    style: { borderRadius: '10px', backdropFilter: 'blur(2px)' },
                  }}
                />
              )}
              style={{maxWidth:'450px'}}
            />
            <div className='location-mapper'>
            <div className="location-container">
              <Autocomplete
                freeSolo
                options={locationOptions}
                getOptionLabel={(option) => option.display_name}
                onInputChange={(event, newInputValue) => {
                  setLocationLabel("Search Location")
                  handleLocationSearch(newInputValue);
                }}
                onChange={handleLocationSelect}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={locationLabel}
                    fullWidth
                    margin="normal"
                    style={{ borderRadius: '10px', backdropFilter: 'blur(2px)' }}
                  />
                )}
              />
              <Button
                variant="contained"
                onClick={handleLocateMe}
                style={{ marginTop: '10px', borderRadius: '10px' }}
              >
                Locate Me
              </Button>
            </div>
            </div>
          </form>
        </div>
      </div>
      <div className='submit-button'>
          <Button
                  type="submit"
                  variant="contained"
                  style={{
                    marginTop: '20px',
                    borderRadius: '40.5px',
                    background: '#10374C',
                    color: '#FFF',
                    width: '300px'
                  }}
                  fullWidth
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
      </div>
    </div>}
    </>
  );
};

export default AddItem;

