import {React, useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import './Trade.css'
import MessageTile from './MessageTile/MessageTile';
import TradeRequestPage from './TradeRequestPage/TradeRequestPage';
import ChatPage from './TradeWindow/ChatPage/ChatPage';
import axios from 'axios';
import Loading from '../Loading/Loading';
const Trade = () => {

  const [chatData,setChatData] = useState(null);
  const navigate = useNavigate();
const [isLoading,setIsLoading] = useState(true);
const [tradeRequest,setTradeRequest] = useState(false);
const [messageList,setMessageList] = useState(true)
const [chatWindow,setChatWindow] = useState(false);
const [selectedMessage,setSelectedMessage] = useState('');


const handleSelectMessage = (message) => {
  setSelectedMessage(message);
  
  if(message.can_chat){
    navigate(`/trade/${message.trade_id}`);
    setChatWindow(true);
    setMessageList(false);
  }
  if(message.can_cancel || message.can_approve){
    navigate(`/trade/${message.trade_id}`);
    setTradeRequest(true)
    console.log("message",message.trade_id)
    // 
    setMessageList(false)
  }
};

useEffect(()=>{
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: 'https://backend.monkeytrade.xyz/api/user_trades',
    headers: { 
      'Authorization': `Bearer ${localStorage.getItem("bearerToken")}`
    }
  };
  axios.request(config)
  .then((response) => {
    setChatData(response.data.data);
    setIsLoading(false);
  })
  .catch((error) => {
    console.log(error);
  });
},[])

  return (
    <div>
      {isLoading && <Loading/>}
      {messageList && <div className='trade-page-container'>
        <h2>Trade</h2>
        { chatData!=null && <div>
              {chatData.map((data, index) => (
                  <MessageTile key={index} message={data} handleSelectMessage={handleSelectMessage} />
              ))}
          </div>}
      </div>}


    </div>
  );
}

export default Trade;