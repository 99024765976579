import React from 'react';
import './Loading.css';
import monkeyAvatar from './../../demoImages/Loading/MonkeyTrade_White.gif'; // Ensure you have an appropriate monkey avatar image

const Loading = () => {
    return (
      <div className="loading-overlay">
        <div className="loading-content">
          <img src={monkeyAvatar} alt="Loading..." className="loading-avatar" />
          {/* <div className="loading-animation">
             <div className="loading-dot"></div>
            <div className="loading-dot"></div>
            <div className="loading-dot"></div> 
          </div> */}
          {/* <p className="loading-text">Loading, please wait...</p> */}
        </div>
      </div>
    );
  };

export default Loading;
