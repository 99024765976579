
import './Create.css'
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import { FiEdit } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import userImage from './../../demoImages/Login/monk.jpg'
import Loading from '../Loading/Loading';
import bcrypt from 'bcryptjs';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
`;

const Heading = styled.h4`
  text-align: center;
  color: black;
  font-family: Mulish, sans-serif;
`;

const ProfileContainer = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

const ProfilePicture = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 5px solid #10374C;
  background-image: url(${props => props.image || userImage});
  background-size: cover;
  background-position: center;
`;

const EditIconContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #F5F5F5;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const ProgressRectangle = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 150px;
  height: 20px;
  background: linear-gradient(90deg, #10374C 31.63%, #09202D 81.99%, #09202D 112.44%);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Mulish;
  font-size: 10px;
  font-weight: 400;
  color: #F5F5F5;
`;


const InputField = styled.div`
  position: relative;
  margin-bottom: 15px;
  width: 100%;
  max-width: 300px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  background: #F5F5F5;
  border: none;
  border-radius: 5px;
  font-family: Mulish;
  font-size: 10px;
  font-weight: 400;
  line-height: 15.485px;
  color: #757575;
`;

const EditIcon = styled(FiEdit)`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #757575;
`;

const SignupButton = styled.button`
  padding: 10px 20px;
  border-radius: 40.5px;
  background: #10374C;
  color: white;
  border: none;
  cursor: pointer;
  font-family: Mulish;
  font-size: 14px;
`;

const Create = () => {
  const navigate = useNavigate();
  const [profileImage, setProfileImage] = useState('');
  const [progress, setProgress] = useState(0);
  const [isDate, setIsDate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState('');
  const [formData, setFormData] = useState({
    email: '',
    name: '',
    phone: '',
    dob: '',
    password: '',
  });
  const [newFormData, setNewFormData] = useState(new FormData());

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setProfileImage(event.target.result);
      };
      reader.readAsDataURL(file);

      // Create a new FormData object
      const updatedFormData = new FormData();
      updatedFormData.append('profile_picture', file,file.name);
      setNewFormData(updatedFormData);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const toggleLoading = () => {
    setIsLoading(!isLoading);
  };

  const handleSubmit = async (e) => {
    toggleLoading()
    e.preventDefault();
    const saltRounds = 10;
    const salt = bcrypt.genSaltSync(saltRounds);
    const hash = bcrypt.hashSync(formData.password, salt);

    newFormData.append('token', token);
    newFormData.append('name', formData.name);
    newFormData.append('email', formData.email);
    newFormData.append('phone', formData.phone);
    newFormData.append('password', hash);
    newFormData.append('dob', formData.dob);

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://backend.monkeytrade.xyz/api/create_user',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: newFormData,
      redirect: "follow"
    };

    try {
      const response = await axios.request(config);
      console.log(response.data)
      if (response.data.status_code === '1000') {
        toggleLoading();
        navigate('/');
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const filledFields = Object.values(formData).filter((field) => field !== '').length;
    setProgress((filledFields / 5) * 100);
  }, [formData]);

  useEffect(() => {
    const urlParams= new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const email = urlParams.get('email');
    setToken(token);
    setFormData((prevData) => ({
      ...prevData,
      email: email,
    }));
  }, []);
  return (
    <>
    {isLoading&&<Loading/>}
    <div className="create-page-container">
      <h1> Monkey Trade </h1>
        <div>
          <div className="create-banner">
              <Container>
      <Heading>Sign up to trade</Heading>
      <ProfileContainer>
        <ProfilePicture image={profileImage} />
        <EditIconContainer>
          <label htmlFor="profile-upload">
            <FiEdit style={{ cursor:'pointer' }}/>
          </label>
          <input
            id="profile-upload"
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={handleImageChange}
          />
        </EditIconContainer>
        <ProgressRectangle>
          {progress}% COMPLETE
        </ProgressRectangle>
      </ProfileContainer>

      <InputField>
        <Input
          type="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleInputChange}
        />
        <EditIcon />
      </InputField>
      <InputField>
        <Input
          type="text"
          name="name"
          placeholder="Name"
          value={formData.name}
          onChange={handleInputChange}
        />
        <EditIcon />
      </InputField>
      <InputField>
        <Input
          type="tel"
          name="phone"
          placeholder="Phone"
          value={formData.phone}
          onChange={handleInputChange}
        />
        <EditIcon />
      </InputField>
      <InputField>
        <Input
          type={isDate?"date":"text"}
          name="dob"
          placeholder="Date of Birth"
          value={formData.dob}
          onChange={handleInputChange}
          onClick={setIsDate}
        />
        <EditIcon />
      </InputField>
      <InputField>
        <Input
          type="password"
          name="password"
          placeholder="Password"
          value={formData.password}
          onChange={handleInputChange}
        />
        <EditIcon />
      </InputField>
      <SignupButton onClick={handleSubmit}>Sign Up</SignupButton>
              </Container>
          </div>
          <div className="create-banner-footer">
          <div className='login-label'> Have an account? <Link to="/"> Log in </Link></div>
          </div>
        </div>
    </div>
    </>
  );
};

export default Create;

