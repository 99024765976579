import React, { useState, useEffect,useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import './HomePage.css';
import bannerImage from '../../demoImages/HomePage/banner.jpg'; // Import your banner image
import Loading from '../Loading/Loading';
import SearchBox from './SearchBox ';
import axios from 'axios';
import ProductDetails from './product';
import FavoriteIcon from '@mui/icons-material/Favorite';

const HomePage = ({setItemIndex, setPhoneNumber , setProfileImage, resetHome, setResetHome}) => {
  const [query, setQuery] = useState('');
  const [customSearch,setCustomSearch] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [productTileList,setProductTileList] = useState([])
  const [isFavouritesFilter,setIsFavouritesFilter] = useState(false);
  const [isHome,setIsHome] = useState(true);
  const [productDetails,setProductDetails] = useState({});
  const [bannerImages,setBannerImages] = useState(['']);
  const [bannerIndex, setBannerIndex] = useState(0);
  const navigate = useNavigate();
  const bannerImage = bannerImages[bannerIndex].url;
  const bannerHeading = bannerImages[bannerIndex].title
  const bannerDescription = bannerImages[bannerIndex].description

  const debounce = (func, wait) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), wait);
    };
  };
  
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const limit = 20;

  const loadProducts = useCallback(async () => {
    if (loading || !hasMore) return;

    setLoading(true);
    try {
      const response = await axios.get(`https://backend.monkeytrade.xyz/api/products`, {
        params: { offset, limit },
        headers: { Authorization: `Bearer ${localStorage.getItem('bearerToken')}` }
      });

      if (response.data.data.length < limit) {
        setHasMore(false); // No more data available
      }

      setProductTileList(prevProducts => {
        // Extract existing IDs from the previous products
        const existingIds = new Set(prevProducts.map(product => product.id));
        const uniqueProducts = response.data.data.filter(product => !existingIds.has(product.id));        
        return [...prevProducts, ...uniqueProducts];
      });
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setLoading(false);
      setIsLoading(false)
    }
  }, [offset, loading, hasMore]);

  const handleScroll = useCallback(debounce(() => {
    if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 5 && !loading) {
      setOffset(prevOffset => prevOffset + limit);
    }
  }, 100), [loading]);

  useEffect(() => {
    if (!customSearch){
    loadProducts();
    }
  }, [offset]);

  useEffect(() => {
    if(resetHome){
      setProductTileList([])
      setCustomSearch(false);
      setOffset(0);
      setHasMore(true)
      setQuery('')
    }
  }, [resetHome]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);



  useEffect(() => {
    const intervalId = setInterval(() => {
      setBannerIndex(prevIndex => (prevIndex + 1) % bannerImages.length);
    }, 10000);

    return () => clearInterval(intervalId);
  }, [bannerImages.length]);

  useEffect(() => {
    axios
      .get('https://backend.monkeytrade.xyz/api/user_details', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('bearerToken')}`,
        },
      })
      .then((response) => {
        let userDetails = response.data.data;
        setProfileImage(userDetails.profile_picture);
        setPhoneNumber(userDetails.phone)
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  useEffect(()=>{

    let bannerConfig = {
      method: 'get',
      maxBodyLength: Infinity,
      url: 'https://backend.monkeytrade.xyz/api/advertising_feed',
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem("bearerToken")}`
      }
    };
    
      axios.request(bannerConfig)
        .then((response) => {
          setBannerImages(response.data.data)
          toggleLoading();
        })
        .catch((error) => {
          console.log(error);
        });
    
  },[])


  const FcLike = ({ color,height,width,strokeWidth }) => (
    <svg
      stroke="black"
      fill={color}
      strokeWidth={strokeWidth||"0"}
      version="1"
      viewBox="0 0 48 48"
      enableBackground="new 0 0 48 48"
      height={height || "1em"}
      width={width||"1em"}
      xmlns="http://www.w3.org/2000/svg"
      style={{ color: color }} 
    >
      <path
        fill={color}
        d="M34,9c-4.2,0-7.9,2.1-10,5.4C21.9,11.1,18.2,9,14,9C7.4,9,2,14.4,2,21c0,11.9,22,24,22,24s22-12,22-24 C46,14.4,40.6,9,34,9z"
      />
    </svg>
  );


  const toggleLoading=()=>{
    setIsLoading(!isLoading);
  }
  const [key, setKey] = useState(0);

  const toggleHome=()=>{
    setKey(prevKey => prevKey + 1);
    setIsHome(!isHome)
  }

  const favouritesUpdate = (id)=>{
    let config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: `https://backend.monkeytrade.xyz/api/favorite?product_id=${id}`,
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem("bearerToken")}`
      }
    };
    axios.request(config)
    .then((response) => {
    })
    .catch((error) => {
      console.log(error);
    });
  }

  const handleLike = (event,id) => {
    event.stopPropagation();
    favouritesUpdate(id);
    setProductTileList(prevData => prevData.map(item => {
      if (item.id === id) {
        return { ...item, is_favorite: !item.is_favorite };
      }
      return item;
    }));
  };

  const getFavourites =()=>{
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: 'https://backend.monkeytrade.xyz/api/favorites',
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem("bearerToken")}`
      }
    };

    axios.request(config)
    .then((response) => {
      setProductTileList(response.data.data)
      if(response.data.data.length==0){
        setHasMore(false)
      }
      setIsLoading(false);
    })
    .catch((error) => {
      console.log(error);
    });
  }

  const getTileList =()=>{
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: 'https://backend.monkeytrade.xyz/api/products',
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem("bearerToken")}`
      }
    };

    axios.request(config)
    .then((response) => {
      setProductTileList(response.data.data)
      if(response.data.data.length==20){
        setOffset(20);
        setHasMore(true)
      }
      setIsLoading(false);
    })
    .catch((error) => {
      console.log(error);
    });
  }
  
  const handleFilter = () => {
    toggleLoading();
    setOffset(0)
    !isFavouritesFilter?getFavourites():getTileList();
    setIsFavouritesFilter(!isFavouritesFilter);
    
  };

  const handleTileClick=(event,productId)=>{
    event.preventDefault()
    toggleLoading();
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `https://backend.monkeytrade.xyz/api/product?id=${productId}`,
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem("bearerToken")}`
      }
    };

    axios.request(config)
    .then((response) => {
      setProductDetails(response.data.data)
      console.log('response:',response.data.data)
      console.log("productdetails~~~~~",productDetails)
      toggleHome();
      setIsLoading(false);
      navigate(`/product/${productId}`);
    })
    .catch((error) => {
      console.log(error);
    });
  }

  return (
    <div key={key}>
    {isLoading&&<Loading/>}
    {isHome &&
    <div className="home-page-container">
      <div className='home-page-view'>
        <div className='search-box'>
        <SearchBox query={query} setQuery={setQuery} setProductTileList={setProductTileList} setHasMore={setHasMore} setCustomSearch={setCustomSearch} setResetHome={setResetHome} />
        </div>
        <div className="main-banner" style={{ backgroundImage: `url(${bannerImage})` }}>
        <div className='main-banner-overlay'>
        <div className="banner-overlay">
          <div className="main-banner-heading">{bannerHeading}</div>
          <div className='banner-content'>
            <span className='banner-description'>{bannerDescription}</span>
            <span className='banner-button'>
              {/* <button className="trade-now-button">Trade Now</button> */}
            </span>
          </div>
        </div>
        </div>
      </div>
      <div className='products-tiles-explore'>
        <div>Welcome to Explore </div>
        <div onClick={handleFilter} style={{cursor:'pointer', alignContent:'center'}}>
        <FavoriteIcon style={{fill:isFavouritesFilter?"#990000":'rgb(202, 202, 202)'}} height={"25px"} width={"25px"} strokeWidth={'2'}/>
        </div>
        </div>
      <div className="product-tiles">
      {!isFavouritesFilter && productTileList.map((product) => (
        <div key={product.id} className="tile"  onClick={(e)=>handleTileClick(e,product.id)} style={{ backgroundImage: `url(${product.product_image})` }}>
          <div className="tile-overlay">
            <div className="overlay-text">
              {product.title}
            </div>
            <button className={`like-button`} onClick={(e) => handleLike(e,product.id)}>
            <FcLike color={product.is_favorite ? 'red' : '#f0f0f0'} />
            </button>
          </div>
        </div>
      ))}
    {isFavouritesFilter && productTileList.map((product) => (
        <div key={product.id} className="tile"  onClick={(e)=>handleTileClick(e,product.id)} style={{ backgroundImage: `url(${product.product_image})` }}>
          <div className="tile-overlay">
            <div className="overlay-text">
              <p>{product.title}</p>
              <p>{product.subTitle}</p>
            </div>
            <button className={`like-button`} onClick={(e) => handleLike(e,product.id)}>
            <FcLike color={product.is_favorite ? 'red' : '#f0f0f0'} />
            </button>
          </div>
        </div>
      ))}

      </div>
      </div>
      </div>}
      {loading && <div style={{display:"flex", justifyContent:"center"}}>Loading...</div>}
      {!hasMore && <div style={{display:"flex", justifyContent:"center"}}>No more products</div>}
  </div>
  );
};

export default HomePage;