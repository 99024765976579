import styled from 'styled-components';
import { FiEdit } from 'react-icons/fi';
import React, { useEffect, useState } from 'react';
import userImage from '../../demoImages/Login/monk.jpg';
import { useNavigate } from 'react-router-dom';
import Loading from '../Loading/Loading';
import axios from 'axios';

const UserProfileEdit = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState('');
  const [profileImage, setProfileImage] = useState('');
  const [progress, setProgress] = useState(0);
  const [isDate, setIsDate] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    dob: '',
  });
  const [newFormData, setNewFormData] = useState(new FormData());

  useEffect(() => {
    axios
      .get('https://backend.monkeytrade.xyz/api/user_details', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('bearerToken')}`,
        },
      })
      .then((response) => {
        let userDetails = response.data.data;
        setIsLoading(false);
        setFormData({
          ...formData,
          profile_picture: userDetails.profile_picture,
          email: userDetails.email,
          name: userDetails.name,
          phone: userDetails.phone,
          dob: userDetails.dob,
        });
        setProfileImage(userDetails.profile_picture);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setProfileImage(event.target.result);
      };
      reader.readAsDataURL(file);
      const updatedFormData = new FormData();
      updatedFormData.append('profile_picture', file,file.name);
      setNewFormData(updatedFormData);
    }
  };

  const handleInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const toggleLoading = () => {
    setIsLoading(!isLoading);
  };

  const handleSubmit = async () => {
    toggleLoading()

    newFormData.append('name', formData.name);
    newFormData.append('phone', formData.phone);
    newFormData.append('dob', formData.dob);

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://backend.monkeytrade.xyz/api/update_user',
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${localStorage.getItem('bearerToken')}`
      },
      data: newFormData,
      redirect: "follow"
    };
    try {
      const response = await axios.request(config);
      console.log(response.data)
      if (response.data.status_code === '1000') {
        toggleLoading();
        navigate('/acc/home');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
      <div style={{marginTop:'150px'}}>
        <Container>
          <ProfileContainer>
            <ProfilePicture image={profileImage} />
            <EditIconContainer>
              <label htmlFor="profile-upload">
                <FiEdit style={{ cursor: 'pointer' }} />
              </label>
              <input
                id="profile-upload"
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                onChange={handleImageChange}
              />
            </EditIconContainer>
          </ProfileContainer>

          <InputField>
            <Input
              disabled={true}
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleInputChange}
            />
          </InputField>
          <InputField>
            <Input
              type="text"
              name="name"
              placeholder="Name"
              value={formData.name}
              onChange={handleInputChange}
            />
            <EditIcon />
          </InputField>
          <InputField>
            <Input
              type="tel"
              name="phone"
              placeholder="Phone"
              value={formData.phone}
              onChange={handleInputChange}
            />
            <EditIcon />
          </InputField>
          <InputField>
            <Input
            disabled={true}
              type={isDate ? 'date' : 'text'}
              name="dob"
              placeholder="Date of Birth"
              value={formData.dob}
              onChange={handleInputChange}
              onClick={() => setIsDate(true)}
            />
          </InputField>
          <SignupButton onClick={handleSubmit}>Update</SignupButton>
        </Container>
        </div>
      )}
    </div>
  );
};

const ProfileContainer = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

const ProfilePicture = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 5px solid #10374c;
  background-image: url(${(props) => props.image || userImage});
  background-size: cover;
  background-position: center;
`;

const EditIconContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const InputField = styled.div`
  position: relative;
  margin-bottom: 15px;
  width: 100%;
  max-width: 300px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  background: #f5f5f5;
  border: none;
  border-radius: 5px;
  font-family: Mulish;
  font-size: 10px;
  font-weight: 400;
  line-height: 15.485px;
  color: #757575;
`;

const EditIcon = styled(FiEdit)`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #757575;
`;

const SignupButton = styled.button`
  padding: 10px 20px;
  border-radius: 40.5px;
  background: #10374c;
  color: white;
  border: none;
  cursor: pointer;
  font-family: Mulish;
  font-size: 14px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
`;

export default UserProfileEdit;
