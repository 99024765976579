import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { MdNavigateNext, MdNavigateBefore } from 'react-icons/md';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css'; // Import CSS for routing machine
import 'leaflet-routing-machine'; // Import Leaflet Routing Machine
import './product.css';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import RequestTrade from './RequestTrade/RequestTrade';
import Loading from '../Loading/Loading';

const ProductDetails = ({setItemIndex , productDetails}) => {
  const { productId } = useParams();
  const [productDetail, setProductDetails] = useState({});
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [mapInitialized, setMapInitialized] = useState(false);
  const [isRequestTrade,setIsRequestTrade] = useState(false)
  let map = null;
  const [isLoading, setIsLoading] = useState(true);
  const [images, setImages] = useState([]);

  const nextImage = () => {
    setCurrentImageIndex((currentImageIndex + 1) % images.length);
  };

  const toggleRequestTrade=() =>{
    setIsRequestTrade(!isRequestTrade)
  }

  const previousImage = () => {
    setCurrentImageIndex((currentImageIndex - 1 + images.length) % images.length);
  };

  useEffect(() => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `https://backend.monkeytrade.xyz/api/product?id=${productId}`,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("bearerToken")}`
        }
      };

    axios.request(config)
    .then((response) => {
      setProductDetails(response.data.data);
      setIsLoading(false);
      console.log(response.data);
      setImages (response.data.data.images)

    const mapContainer = document.getElementById('map');
    const coordinates = [response.data.data.latitude || '0', response.data.data.longitude || '0'];

    map = L.map(mapContainer).setView(coordinates, 10); // Assign map variable
    setMapInitialized(true);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);

    L.marker(coordinates).addTo(map);

    return () => {
      if (map) {
        map.remove();
        map = null;
      }
    };
    })
    .catch((error) => {
      console.log(error);
    });
  },[])


  useEffect(() => {
      
  }, []);

  useEffect(() => {
    if (mapInitialized) {
      const source = [37.7749, -122.4194]; // San Francisco
      const target = [34.0522, -118.2437]; // Los Angeles
      displayRoute(source, target);
    }
  }, [mapInitialized]);

  // Function to display route between two coordinates
  const displayRoute = (source, target) => {
    if (!map) return; // Guard against null or undefined map

    L.Routing.control({
      waypoints: [
        L.latLng(source),
        L.latLng(target)
      ],
      lineOptions: {
        styles: [{ color: 'blue', opacity: 0.8, weight: 4 }]
      }
    }).addTo(map);
  };

  
  const handleNextClick = (event)=>{
    event.preventDefault();
    toggleRequestTrade();
  }

  return (
  <>
  {isLoading?<Loading/>:
  <div>
    {isRequestTrade&&<RequestTrade setItemIndex={setItemIndex} toggleRequestTrade={toggleRequestTrade} productID={productDetail.id}/>}
    {!isRequestTrade && <div className="main-div">
      <div className='content-wrapper'>
        <div className="left-column">
          <div className="image-row">
            <div className="image-container" style={{ backgroundImage: `url(${images[currentImageIndex].image_url})` }}>
              <div className="overlay-gradient">
                <div className="next-arrow-left" onClick={previousImage}>
                  <MdNavigateBefore size={40} />
                </div>
                <div className="next-arrow-right" onClick={nextImage}>
                  <MdNavigateNext size={40} />
                </div>
                {/* <div className="overlay-text-product-item">
                  <div className="product-title"></div>
                  <div className="product-year">2015</div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="description-map-row">
            <div className="description">
              <h2>{productDetail.title}</h2>
              <p>{productDetail.description}</p>
            </div>
            <div id="map" className="map"></div>
          </div>
        </div>
        <div className="right-column">
          <div className="tags-container">
          {productDetail.tags.map((tag, index) => (
            <div key={index} className={`tag ${tag.matched ? `matched`: ''}`}>{tag.tag}</div>
          ))}
          </div>
        </div>
      </div>
      <div className="bottom-button-container">
        <button className="next-button" onClick={(e)=>handleNextClick(e)}>NEXT</button>
      </div>
    </div>}
    </div>}
    </>
  );
};

export default ProductDetails;
