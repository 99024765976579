// App.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './RequestTrade.css';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import axios from 'axios';
import Loading from '../../Loading/Loading';
import Popup from '../../Popup/Popup';

function ProductTile({ product, onClick }) {
  return (
    <div className={`product-tile ${product.select ? 'selected' : ''}`} onClick={() => onClick(product.product_id)}>
      <div className="product-title">{product.title}</div>
      <div className="product-description">{product.sub_title}</div>
      <div
        className="product-image"
        style={{ background: `url(${product.image_url}) lightgray 50% / cover no-repeat` }}
      >
        <div className="tile-overlay"></div>
      </div>
    </div>
  );
}

function TradeSection({ profileImage, products, toggleSelect,name }) {
  return (
    <div className="section">
      <div
        className="profile-image"
        style={{ background: `url(${profileImage}) lightgray 50% / cover no-repeat` }}
      ></div>
      <button className='match-button-trade'>{name}</button>
      <div className="products">
        {products.map((product) => (
          <ProductTile key={product.product_id} product={product} onClick={toggleSelect} />
        ))}
      </div>
    </div>
  );
}

function RequestTrade({setItemIndex,toggleRequestTrade,productID}) {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [submitButtonDisabled,setSubmitButtonDisabled] = useState(true);
  const handlePopupClose = () => {
    setItemIndex(4);
    navigate('/acc/trade')
    toggleRequestTrade();
    setShowPopup(false);
  };

  const handleHomeClick = () => {
    // Handle home button click
  };

  const handleTradeClick = () => {
    // Handle trade button click
  };

  const submitTrade = () => {
    toggleLoading();
    let data = {
      "receiver_id":tradeData.other_user_trade_data.user_details.user_id ,
      "products":selectedProducts
    }

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://backend.monkeytrade.xyz/api/trade',
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem("bearerToken")}`
      },
      data:data
    }

    axios.request(config)
            .then((response) => {
              console.log(response.data)
              setShowPopup(true)
              setIsLoading(false);
            })
            .catch((error) => {
              console.log(error);
            });

  };

  const [isTradeRequest,setIsTradeRequest]=useState(true);
  const [isLoading,setIsLoading] = useState(true);
  const [tradeData,setTradeData] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const isProductSelected=(selectedProduct) => {
    if(tradeData!=null){
    const currentUserProductIds = tradeData.current_user_trade_data.product_details.map(product => product.product_id);
    const otherUserProductIds = tradeData.other_user_trade_data.product_details.map(product => product.product_id);

    const hasCurrentUserProduct = currentUserProductIds.some(id => selectedProduct.includes(id));
    const hasOtherUserProduct = otherUserProductIds.some(id => selectedProduct.includes(id));

    return hasCurrentUserProduct && hasOtherUserProduct;
    }
}


  const toggleLoading = ()=>{
    setIsLoading(!isLoading);
  }

  useEffect(()=>{
    if(tradeData){
    setSubmitButtonDisabled(!isProductSelected(selectedProducts));
    }
  },[selectedProducts])

  useEffect(()=>{
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `https://backend.monkeytrade.xyz/api/trade_product?product_id=${productID}`,
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem("bearerToken")}`
      }
    };


    axios.request(config)
    .then((response) => {
        setTradeData(response.data.data)
        response.data.data.current_user_trade_data.product_details.forEach(product => {
          if (product.select && !selectedProducts.includes(product.product_id)) {
            selectedProducts.push(product.product_id);
          }
        });
    
        // Extract selected products from other_user_trade_data
        response.data.data.other_user_trade_data.product_details.forEach(product => {
          if (product.select  && !selectedProducts.includes(product.product_id)) {
            selectedProducts.push(product.product_id);
          }
        });
        toggleLoading();
    })
    .catch((error) => {
      console.log(error);
    });
  },[])

  const toggleSelect = (id) => {

    if(selectedProducts.includes(id)){
      setSelectedProducts(selectedProducts.filter(productId => id !== productId)); 
    } else {
      setSelectedProducts([...selectedProducts, id]);
    }
    setTradeData((prevData) => {
      const updateSelection = (product_details) =>
        product_details.map((product) =>
          product.product_id === id ? { ...product, select: !product.select } : product
        );


      return {
        current_user_trade_data: {
          ...prevData.current_user_trade_data,
          product_details: updateSelection(prevData.current_user_trade_data.product_details),
        },
        other_user_trade_data: {
          ...prevData.other_user_trade_data,
          product_details: updateSelection(prevData.other_user_trade_data.product_details),
        },
      };
    });
  };

  const handleBackClick = () => {
    toggleRequestTrade()
  };

  return (<>
    {isLoading?<Loading/>:
    <div style={{marginLeft:'10px'}}>
    <div className="container-trade-request">
      <TradeSection
        profileImage={tradeData.other_user_trade_data.user_details.profile_picture}
        products={tradeData.other_user_trade_data.product_details}
        toggleSelect={toggleSelect}
        name={tradeData.other_user_trade_data.user_details.name}
      />
      <TradeSection
        profileImage={tradeData.current_user_trade_data.user_details.profile_picture}
        products={tradeData.current_user_trade_data.product_details}
        toggleSelect={toggleSelect}
        name={tradeData.current_user_trade_data.user_details.name}
      />
    </div>
    <div  className='trade-request-button'>
        {isTradeRequest && <div className='trade-request-trade-button'>
            <button disabled={submitButtonDisabled} className={submitButtonDisabled?'submit-button-trade-disabled':'submit-button-trade'} onClick={submitTrade}>TRADE</button>
        </div>}
      <Popup 
        show={showPopup} 
        onClose={handlePopupClose} 
        onHomeClick={handleHomeClick} 
        onTradeClick={handleTradeClick} 
      />
    </div>
    </div>}
    </>
  );
}

export default RequestTrade;
