import {React, useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import TradeRequestPage from '../TradeRequestPage/TradeRequestPage';
import ChatPage from './ChatPage/ChatPage';
import axios from 'axios';
import Loading from '../../Loading/Loading';
const TradeWindow = () => {

  const [chatData,setChatData] = useState(null);
const [isLoading,setIsLoading] = useState(true);
const [tradeRequest,setTradeRequest] = useState(false);
const [chatWindow,setChatWindow] = useState(false);
const [selectedMessage,setSelectedMessage] = useState('');
const { tradeId } = useParams();


useEffect(()=>{
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `https://backend.monkeytrade.xyz/api/trade_product?trade_id=${tradeId}`,
    headers: { 
      'Authorization': `Bearer ${localStorage.getItem("bearerToken")}`
    }
  };
  axios.request(config)
  .then((response) => {
    setChatData(response.data.data);
    setIsLoading(false);
        if(response.data.data.can_chat){
          setChatWindow(true);
        }
        if(response.data.data.can_cancel || response.data.data.can_approve){
          setTradeRequest(true)
          console.log("message",response.data.data)
          // navigate(`/trade/${message.trade_id}`);
        }
  })
  .catch((error) => {
    console.log(error);
  });
},[])

  return (
    <div>
      {isLoading && <Loading/>}
      <div style={{marginTop:"80px"}}>
      {chatWindow&& <div className='trade-page-container'> <ChatPage  setChatWindow={setChatWindow} selectedMessage={chatData} /></div>}
      {tradeRequest&&<div className='trade-page-container'><TradeRequestPage setChatWindow={setChatWindow}  setTradeRequest={setTradeRequest} selectedMessage={chatData} /></div>}
      </div>
    </div>
  );
}

export default TradeWindow;