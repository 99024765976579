import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './ProfileHeader.css';
import { FiEdit } from 'react-icons/fi';
import ItemTile from './ItemTile/ItemTile';
import Loading from '../Loading/Loading';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { GoHomeFill  } from "react-icons/go";
import { MdMessage } from "react-icons/md";
import { FaHeart,FaPlusSquare,FaUser, FaLock   } from "react-icons/fa";
import { ReactComponent as AddIcon } from '../../demoImages/Header/addItem.svg';
import { ReactComponent as NotificationsIcon } from '../../demoImages/Header/notifications.svg';
import { ReactComponent as TradeIcon } from '../../demoImages/Header/trade.svg';

import HomeIcon from '@mui/icons-material/Home';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import MarkChatUnreadIcon from '@mui/icons-material/MarkChatUnread';



const ProfileHeader = ({ profileImageUrl, userName, phoneNumber,setLoggedIn,loadProfile,setEditProductId,activeItem, setActiveItem, setResetHome }) => {

  const [items, setItems] = useState([]);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(()=>{
      axios.get('https://backend.monkeytrade.xyz/api/my_products', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('bearerToken')}`
        }
      })
      .then(response => {
        setItems(response.data.data)
        setIsLoading(false)
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
    },[])

    useEffect(()=>{
      axios.get('https://backend.monkeytrade.xyz/api/my_products', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('bearerToken')}`
        }
      })
      .then(response => {
        setItems(response.data.data)
        setIsLoading(false)
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });

    },[loadProfile])
  
    
    const handleProfileEdit =()=>{
      setIsDropdownOpen(false);
      navigate("/user-profile/edit")
    }
      const handleDelete = (id) => {
        console.log(id);

        let config = {
          method: 'put',
          maxBodyLength: Infinity,
          url: `https://backend.monkeytrade.xyz/api/delete_product?id=${id}`,
          headers: { 
            'Authorization': `Bearer ${localStorage.getItem("bearerToken")}`
          },
        };
    
        axios.request(config)
          .then((response) => {
            setItems(items.filter(item => item.id !== id));
          })
          .catch((error) => {
            console.log(error);
          });
      };
    
      const handleEdit = (id) => {
        setEditProductId(id);
        setIsDropdownOpen(false);
        navigate("/product/edit");
        console.log('Edit item with id:', id);
      };
  const [isLoading,setIsLoading] = useState(true);
  const navigate = useNavigate()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isActive,setIsActive] = useState(true)
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setIsDropdownOpen(false);
    }
  };

  const handleLogout = ()=>{
    setIsLoading(true);
    toggleDropdown()

    const options = {
      method: 'POST',
      url: 'https://backend.monkeytrade.xyz/api/logout',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('bearerToken')}`
      }
    };
    axios.request(options).then(function (response) {
      localStorage.removeItem("bearerToken")
      setLoggedIn(false)
      navigate('/')
      setIsLoading(false)
    }).catch(function (error) {
      console.error(error);
    });

  }

  const toggleActive =()=>{
    setIsActive(!isActive)
    setIsDropdownOpen(true)
  }

  const handleItemClick = (index) => {
    setResetHome(true);
    setActiveItem(index);
  };
  return (<>
  {isLoading && <Loading/>}
  <div className='upper-head-container'>
  <div className='upper-heading'>
    <div className='upper-heading-inner'>
    <div className="side-header-head">Monkey Trade.</div>
    <div className='menu-container'>
    <ul className="menu-list">
        <li>
          <Link
            to="/acc/home"
            className={activeItem === 0 ? 'active' : ''}
            onClick={() => handleItemClick(0)}
          >
            <div style={{  alignItems: 'center', justifyContent:'center' }}>
              <div style={{  alignItems: 'center', justifyContent:'center', display:'flex', }}><HomeIcon style={{maxWidth:'25', maxHeight:'25'}} /> </div>
            </div>
          </Link>
        </li>
        <li>
          <Link
            to="/acc/create"
            className={activeItem === 3 ? 'active' : ''}
            onClick={() => handleItemClick(3)}
          >
            <div style={{  alignItems: 'center', justifyContent:'center' }}>
              <div style={{  alignItems: 'center', justifyContent:'center', display:'flex', }}><AddToPhotosIcon style={{maxWidth:'25', maxHeight:'25'}} /> </div>
            </div>
          </Link>
        </li>
        <li>
          <Link
            to="/acc/notification"
            className={activeItem === 2 ? 'active' : ''}
            onClick={() => handleItemClick(2)}
          >
            <div style={{  alignItems: 'center', justifyContent:'center' }}>
              <div style={{  alignItems: 'center', justifyContent:'center', display:'flex', }}><NotificationsActiveIcon style={{maxWidth:'25', maxHeight:'25'}} /> </div>
            </div>
          </Link>
        </li>
        <li>
          <Link
            to="/acc/trade"
            className={activeItem === 4 ? 'active' : ''}
            onClick={() => handleItemClick(4)}
          >
            <div style={{  alignItems: 'center', justifyContent:'center' }}>
              <div style={{  alignItems: 'center', justifyContent:'center', display:'flex', }}><MarkChatUnreadIcon  style={{maxWidth:'25', maxHeight:'25'}} /> </div>
            </div>
          </Link>
        </li>
      </ul>
    </div>
    
      <div
        className="profile-circle"
        style={{ backgroundImage: `url(${profileImageUrl})` }}
        onClick={toggleDropdown}
      ></div>
      {isDropdownOpen && (
        <div className="profile-dropdown" ref={dropdownRef}>
          <div className="profile-dropdown-header">
            <div className="profile-details">
              <h3>{userName}</h3>
              <p>{phoneNumber}</p>
            </div>
            <div className='profile-circle-container enlarged'>
              <div
                className="profile-circle enlarged"
                style={{ backgroundImage: `url(${profileImageUrl})` }}
                onClick={toggleDropdown}
              ></div>
              <div className="edit-icon" onClick={handleProfileEdit}><FiEdit style={{ cursor: 'pointer' }} /></div>
              <div className={isActive ? "active-indicator" : "inactive-indicator"} onClick={toggleActive}></div>
            </div>
          </div>
          <div className='profile-my-items'>
            <div className='profile-my-items-heading'>
              My Items
            </div>
            <div className='profile-my-items-list'>
              {items.length > 0 ?
                items.map(item => (
                  <ItemTile
                    key={item.id}
                    item={item}
                    onDelete={handleDelete}
                    onEdit={handleEdit}
                  />
                )) : "No items to display. Please Add an item."}
            </div>
          </div>
          <div className='profile-logout-button-container'>
            <div className='logout-button' onClick={handleLogout}>Log out</div>
          </div>
        </div>
      )}
    </div>  
    </div>
    </div>
    
    </>
  );
};

export default ProfileHeader;