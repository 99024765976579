import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import './SearchBox.css'; // Import CSS for styling
import { RiSearch2Fill, RiFilter2Fill, RiPlayFill } from "react-icons/ri";
import SearchIcon from '@mui/icons-material/Search';

const SearchBox = ({setQuery,query, setProductTileList,setCustomSearch,setResetHome}) => {
 
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const handleSearch =async ()=>{
    setResetHome(false);
    setCustomSearch(true);
    try {
      const response = await axios.get(`https://backend.monkeytrade.xyz/api/products?search=${query}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('bearerToken')}` }
      });
      setShowSuggestions(false)

      setProductTileList(response.data.data)
      console.log("Data:",response.data.data)
    } catch (error) {
      console.error('Error fetching search:', error);
    }
  }

  const handleTypeSearch= async (suggestion) =>{
    setResetHome(false);
    setCustomSearch(true);
    try {
      const response = await axios.get(`https://backend.monkeytrade.xyz/api/products?search=${suggestion.id}&search_type=${suggestion.type}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('bearerToken')}` }
      });
      setShowSuggestions(false)
      setProductTileList(response.data.data)
      console.log("Data:",response.data.data)
    } catch (error) {
      console.error('Error fetching search:', error);
    }
  }
  const fetchSuggestions = useCallback(async () => {
    try {
      const response = await axios.get(`https://backend.monkeytrade.xyz/api/search?key=${query}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('bearerToken')}` }
      });
      setSuggestions(response.data.data);
      setShowSuggestions(true);
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    }
  }, [query]);

  useEffect(() => {
    if (query.length >= 3) {
      fetchSuggestions();
    } else {
      setSuggestions([]);
      setShowSuggestions(false);
    }
  }, [query, fetchSuggestions]);

  const handleChange = (event) => {
    setQuery(event.target.value);
  };

  const handleSuggestionClick = (suggestion) => {
    setQuery(suggestion.name);
    setShowSuggestions(false);
    handleTypeSearch(suggestion);
  };

  const handleInputBlur = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setShowSuggestions(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div className="search-box-container">
      <div className="search-wrapper">
        <input
          type="text"
          placeholder="What are you looking for"
          value={query}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          className="search-input"
          onFocus={() => setShowSuggestions(true)}
          onBlur={handleInputBlur}
        />
        <span className="search-icon" onClick={handleSearch}><SearchIcon /></span>
      </div>
      {showSuggestions && suggestions.length > 0 && (
        <ul className="suggestions-list">
          {suggestions.map((suggestion) => (
            <li
              key={suggestion.id}
              onMouseDown={() => handleSuggestionClick(suggestion)}
              className="suggestion-item"
            >
              {suggestion.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SearchBox;
