// App.js

import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './TradeRequestPage.css';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Loading from '../../Loading/Loading';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const dummyData = {
  tradeSender: {
    profileImage: "https://rajueditor.com/wp-content/uploads/2023/06/islamic-pics-for-dp-girl-islamic-dp-images-girl.jpg",
    products: [
      { name: "Product 1", description: "Description 1", id: "1", image: "https://www.apple.com/newsroom/images/product/iphone/standard/Apple-iPhone-14-iPhone-14-Plus-hero-220907_Full-Bleed-Image.jpg.large.jpg", selected: false },
      { name: "Product 2", description: "Description 2", id: "2", image: "https://photos5.appleinsider.com/gallery/product_pages/113-hero.jpg", selected: true },
      { name: "Product 3", description: "Description 3", id: "3", image: "https://images.durian.in/Durian/durian/product/800x800/product_2022100615001665068425_77055.jpg", selected: false },
      { name: "Product 4", description: "Description 7", id: "4", image: "https://www.findingtheuniverse.com/wp-content/uploads/2021/05/Canon-EOS-R5-review_by_Laurence-Norah.jpg", selected: false },
      { name: "Product 5", description: "Description 8", id: "5", image: "https://images-cdn.ubuy.co.in/6427c0eb21644325981fda95-sony-playstation-4-pro-1tb-console.jpg", selected: true },
      { name: "Product 6", description: "Description 9", id: "6", image: "https://static01.nyt.com/images/2024/01/06/books/06kristin-hannah-cover/06kristin-hannah-cover-articleLarge.jpg?quality=75&auto=webp&disable=upscale", selected: false },

    ],
  },
  tradeReceiver: {
    profileImage: "https://image.winudf.com/v2/image1/Y29tLkJveXMuUHJvZmlsZVBpY3R1cmVzX3NjcmVlbl8wXzE3MDAxNTYzNTVfMDMx/screen-0.jpg?fakeurl=1&type=.jpg",
    products: [
      { name: "Product 4", description: "Description 4", id: "4", image: "https://www.findingtheuniverse.com/wp-content/uploads/2021/05/Canon-EOS-R5-review_by_Laurence-Norah.jpg", selected: false },
      { name: "Product 5", description: "Description 5", id: "5", image: "https://images-cdn.ubuy.co.in/6427c0eb21644325981fda95-sony-playstation-4-pro-1tb-console.jpg", selected: true },
      { name: "Product 6", description: "Description 6", id: "6", image: "https://static01.nyt.com/images/2024/01/06/books/06kristin-hannah-cover/06kristin-hannah-cover-articleLarge.jpg?quality=75&auto=webp&disable=upscale", selected: false },
    ],
  },
  isTradeAcceptDeclineRequest:true,
  isTradeRequest : false
};

function ProductTile({ product, onClick }) {
  return (
    <div className={`product-tile ${product.select ? 'selected' : ''}`} onClick={() => onClick(product.product_id)}>
      <div className="product-title">{product.title}</div>
      <div className="product-description">{product.sub_title}</div>
      <div
        className="product-image"
        style={{ background: `url(${product.image_url}) lightgray 50% / cover no-repeat` }}
      >
        <div className="tile-overlay"></div>
      </div>
    </div>
  );
}

function TradeSection({ profileImage, products, toggleSelect, name }) {
  return (
    <div className="section">
      <div
        className="profile-image"
        style={{ background: `url(${profileImage}) lightgray 50% / cover no-repeat` }}
      ></div>
      <button className='match-button-trade'>{name}</button>
      <div className="products">
        {products.map((product) => (
          <ProductTile key={product.product_id} product={product} onClick={toggleSelect} />
        ))}
      </div>
    </div>
  );
}

function TradeRequestPage({setChatWindow,setTradeRequest,setMessageList,selectedMessage}) {
  const [tradeDetails,setTradeDetails] = useState(selectedMessage);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const navigate=useNavigate();
  useEffect(()=>{
    console.log(tradeDetails)
    tradeDetails.current_user_trade_data.product_details.forEach(product => {
        if (product.select && !selectedProducts.includes(product.product_id)) {
          selectedProducts.push(product.product_id);
        }
      });
      // Extract selected products from other_user_trade_data
      tradeDetails.other_user_trade_data.product_details.forEach(product => {
        if (product.select  && !selectedProducts.includes(product.product_id)) {
          selectedProducts.push(product.product_id);
        }
      });
      setIsLoading(false);
  },[])

  const [isLoading,setIsLoading] = useState(true);
  const [data, setData] = useState(tradeDetails);
  const [isTradeAcceptDeclineRequest,setIsTradeAcceptDeclineRequest] = useState(dummyData.isTradeAcceptDeclineRequest);
  const [isTradeRequest,setIsTradeRequest]=useState(dummyData.isTradeRequest);

  const updateSelectedProducts = (id)=>{
    if(selectedProducts.includes(id)){
      setSelectedProducts(selectedProducts.filter(productId => id !== productId)); 
    } else {
      setSelectedProducts([...selectedProducts, id]);
    }
  }
  const toggleSelect = (id) => {
    updateSelectedProducts(id);
    console.log("Products::",selectedProducts);
    setTradeDetails((prevData) => {
      const updateSelection = (products) =>
        products.map((product) =>
          product.product_id === id ? { ...product, select: !product.select } : product
        );

      return {
        current_user_trade_data: {
          ...prevData.current_user_trade_data,
          product_details: updateSelection(prevData.current_user_trade_data.product_details),
        },
        other_user_trade_data: {
          ...prevData.other_user_trade_data,
          product_details: updateSelection(prevData.other_user_trade_data.product_details),
        },
      };
    });
  };

  const handleBackClick = () => {
    setMessageList(true);
    setTradeRequest(false);
  };

  const handleAcceptTrade = ()=>{
    let config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: `https://backend.monkeytrade.xyz/api/trade?id=${selectedMessage.trade_id}`,
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem("bearerToken")}`
      },
      data : {"status" : "ACCEPTED"}
    };

    axios.request(config)
  .then((response) => {
    console.log(response.data.data);
    setTradeRequest(false);
    setChatWindow(true);
    setIsLoading(false);
  })
  .catch((error) => {
    console.log(error);
  });


  }

  const handleDeclineTrade =()=>{
      let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `https://backend.monkeytrade.xyz/api/trade?id=${selectedMessage.trade_id}`,
        headers: { 
          'Authorization': `Bearer ${localStorage.getItem("bearerToken")}`
        },
        data : {"status" : "REJECTED"}
      };

      axios.request(config)
  .then((response) => {
    console.log(response.data.data);
    navigate('/acc/trade')
    setIsLoading(false);
  })
  .catch((error) => {
    console.log(error);
  });
  }

  const handleCancelTrade =()=>{
      let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `https://backend.monkeytrade.xyz/api/trade?id=${selectedMessage.trade_id}`,
        headers: { 
          'Authorization': `Bearer ${localStorage.getItem("bearerToken")}`
        },
        data : {"status" : "CANCELLED"}
      }; 

      axios.request(config)
  .then((response) => {
    console.log(response.data.data);
    navigate('/acc/trade')
    setIsLoading(false);
  })
  .catch((error) => {
    console.log(error);
  });
  }

  return (
    isLoading?<Loading/>:
    <div>
    <div className="container-trade-request">
      <TradeSection
        profileImage={tradeDetails.other_user_trade_data.user_details.profile_picture}
        products={tradeDetails.other_user_trade_data.product_details}
        toggleSelect={toggleSelect}
        name={tradeDetails.other_user_trade_data.user_details.name}
      />

      <TradeSection
        profileImage={tradeDetails.current_user_trade_data.user_details.profile_picture}
        products={tradeDetails.current_user_trade_data.product_details}
        toggleSelect={toggleSelect}
        name={tradeDetails.current_user_trade_data.user_details.name}
      />
    </div>
    <div className='trade-request-button'>
        {isTradeRequest && <div className='trade-request-trade-button'>
            <button className='submit-button-trade'>TRADE</button>
        </div>}
        {!selectedMessage.can_cancel ? <div className='trade-accept-decline-tradebutton'>
        <button className='accept-button-trade' onClick={handleAcceptTrade}>ACCEPT</button>
        <button className='decline-button-trade' onClick={ ()=>{
          setIsLoading(true);
          handleDeclineTrade()}
          }>DECLINE</button>
        </div> :
        <div className='trade-accept-decline-tradebutton'>
        <button className='decline-button-trade' onClick={()=>{
          setIsLoading(true);
          handleCancelTrade();
        }
          }>CANCEL</button>
        </div>}
    </div>
    </div>
  );
}

export default TradeRequestPage;
