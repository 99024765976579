import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Slider, MenuItem, Autocomplete, Chip } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import axios from 'axios';
import './EditProduct.css';
import Loading from '../Loading/Loading';
import { RiUpload2Line } from 'react-icons/ri';

const categories = ['Category 1', 'Category 2', 'Category 3'];



const EditProduct = ({ productId }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [prodCategoryList, setProdCategoryList] = useState(categories);
  const [images, setImages] = useState([]);
  const [locationLabel, setLocationLabel] = useState("Search Location");
  const [marks, setMarks] = useState([{ value: 0, label: '0$' }, { value: 1000, label: '1000$' }]);
  const [wishlist, setWishlist] = useState([]);
  const [selectedYear, setSelectedYear] = useState('');
  const [productData, setProductData] = useState('');
  const [formData, setFormData] = useState({
    title: productData.title || '',
    description: productData.description || '',
    category_id: productData.category_id || '',
    year: productData.model_year || '',
    lower_price_range: productData.lower_price_range || '',
    higher_price_range: productData.higher_price_range || '',
    images: (productData.images || []).map(image => image.image_url),
    latitude: productData.latitude || '',
    longitude: productData.longitude || '',
    tags: productData.tags || [],
    tagNames:(productData.tags || []).map(tag => tag.tag)
  });
  const [locationOptions, setLocationOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    console.log(selectedYear);
  }, [selectedYear]);

  useEffect(() => {
    axios.get('https://backend.monkeytrade.xyz/api/product-categories', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('bearerToken')}`
      }
    })
      .then(response => {
        console.log('Data:', response.data);
        setProdCategoryList(response.data.data);
        return axios.get('https://backend.monkeytrade.xyz/api/wish_list', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('bearerToken')}`
          }
        });
      })
      .then(response => {
        console.log('Data:', response.data);
        setWishlist(response.data.data);
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `https://backend.monkeytrade.xyz/api/product?id=${productId}`,
          headers: {
            'Authorization': `Bearer ${localStorage.getItem("bearerToken")}`
          }
        };
        return axios.request(config);
      })
      .then(response => {
        console.log("Product Details", response.data.data);
        const productDetails = response.data.data;
        setProductData(productDetails);
        setFormData({
          ...formData,
          title: productDetails.title,
          description: productDetails.description,
          category_id: productDetails.category_id,
          lower_price_range: productDetails.lower_price_range,
          higher_price_range: productDetails.higher_price_range,
          latitude: productDetails.latitude,
          longitude: productDetails.longitude,
          images: productDetails.images.map(image => image.image_url),
          tags: productDetails.tags,
          tagNames: (productDetails.tags).map(tag => tag.tag)
      })
        setImages( response.data.data.images.map(image => image.image_url));
        axios.get(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${response.data.data.latitude}&lon=${response.data.data.longitude}`)
          .then(response => {
            const address = response.data.display_name;
            setLocationLabel(address);
            setIsLoading(false);
          })
          .catch(error => {
            console.error("There was an error retrieving the location!", error);
          });
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  useEffect(() => {
    console.log("Data", formData);
  }, [formData]);

  const handleImageUpload = (event) => {
    if (images.length < 6) {
      const file = event.target.files[0];
      setImages([...images, URL.createObjectURL(file)]);
      const updatedImages = [...formData.images, file];
      setFormData({ ...formData, images: updatedImages });
    }
  };

  const handleRemoveImage = (index) => {
    const newImages = images.filter((_, imgIndex) => imgIndex !== index);
    setImages(newImages);
    const newImageFiles = formData.images.filter((_, imgIndex) => imgIndex !== index);
    setFormData({ ...formData, images: newImageFiles });
  };

  const handleSliderChange = (event, newValue) => {
    setFormData({ ...formData, lower_price_range: newValue[0], higher_price_range: newValue[1] });
    setProductData({...productData, lower_price_range: newValue[0], higher_price_range: newValue[1]})
  };

  const handleLocationSearch = (query) => {
    axios.get(`https://nominatim.openstreetmap.org/search?q=${query}&format=json&addressdetails=1`)
      .then(response => {
        setLocationOptions(response.data);
      })
      .catch(error => {
        console.error("There was an error searching the location!", error);
      });
  };

  const handleLocationSelect = (event, value) => {
    setFormData({ ...formData, latitude: value ? value.lat : '', longitude: value ? value.lon : '' });
  };

  const handleLocateMe = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        axios.get(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`)
          .then(response => {
            const address = response.data.display_name;
            setLocationLabel(address);
            setFormData({ ...formData, latitude: latitude.toString(), longitude: longitude.toString() });
          })
          .catch(error => {
            console.error("There was an error retrieving the location!", error);
          });
      },
      (error) => {
        console.error("Error retrieving location:", error);
      }
    );
  };

  const handleTagInputChange = (event, newValue) => {
    const tagIds = newValue.map(tagName => {
      const tagObj = wishlist.find(tag => tag.tag === tagName);
      return tagObj ? tagObj.id : null;
    }).filter(id => id !== null);
    setFormData({ ...formData, tags: tagIds ,tagNames:newValue });
  };

  const toggleLoading = () => {
    setIsLoading(!isLoading);
  };

  const handleSubmit = async (event) => {
    console.log("formData",formData)
    toggleLoading();
    event.preventDefault();
    const formDataToSend = new FormData();
    formDataToSend.append("title", formData.title);
    formDataToSend.append("description", formData.description);
    formDataToSend.append("category_id", formData.category_id);
    formDataToSend.append("lower_price_range", formData.lower_price_range);
    formDataToSend.append("higher_price_range", formData.higher_price_range);
    formDataToSend.append("latitude", formData.latitude);
    formDataToSend.append("longitude", formData.longitude);
    formDataToSend.append("tags", JSON.stringify(formData.tags));
    formData.images.forEach((image, index) => {
      const key = "images" + (index + 1);
      formDataToSend.append(key, image);
    });

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `https://backend.monkeytrade.xyz/api/update_product?id=${productId}`,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${localStorage.getItem('bearerToken')}`,
      },
      data: formDataToSend,
      redirect: "follow"
    };

    try {
      const response = await axios.request(config);
      console.log(response.data);
      setIsLoading(false);
      navigate('/acc/home');
    } catch (error) {
      console.log(error);
    }
  };

  const getTopTags = () => {
    return wishlist.slice(0, 10);
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  const filteredTags = wishlist.filter(tag => tag.tag.toLowerCase().includes(inputValue.toLowerCase())).slice(0, 10);

  return (
    <>
      {isLoading ? <Loading /> :
        <div className='add-items-container'>
          <h2>Edit Item</h2>
          <div className="container">
            <div className="left-section">
              <div className="image-upload-container">
                {images.map((image, index) => (
                  <div key={index} className="uploaded-image-wrapper">
                    <img src={image} alt={`upload-${index}`} className="uploaded-image" />
                    <button className="close-button" onClick={() => handleRemoveImage(index)}>✖</button>
                  </div>
                ))}
                {images.length < 6 && (
                  <div className="image-upload-box">
                    <input type="file" accept="image/*" onChange={handleImageUpload} className="file-input" />
                    <div className="upload-icon"><RiUpload2Line size={30} /></div>
                  </div>
                )}
              </div>
            </div>
            <div className="right-section">
              <form onSubmit={handleSubmit}>
                <TextField
                  label="Title"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  defaultValue={productData.title}
                  style={{ borderRadius: '10px', backdropFilter: 'blur(2px)', width: '450px' }}
                  onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                />
                <TextField
                  label="Description"
                  variant="outlined"
                  multiline
                  rows={3}
                  defaultValue={productData.description}
                  fullWidth
                  margin="normal"
                  style={{ borderRadius: '10px', backdropFilter: 'blur(2px)' }}
                  onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                />
                <TextField
                  label="Category"
                  select
                  fullWidth
                  margin="normal"
                  defaultValue={productData.category_id}
                  style={{ borderRadius: '10px', backdropFilter: 'blur(2px)' }}
                  onChange={(e) => setFormData({ ...formData, category_id: e.target.value })}
                >
                  {prodCategoryList.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.title}
                    </MenuItem>
                  ))}
                </TextField>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    views={['year']}
                    label="Year"
                    value={productData.model_year}
                    onChange={(newValue) => {
                      setSelectedYear(newValue.toJSON().toString().slice(0, 4));
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    fullWidth
                    margin="normal"
                    style={{ borderRadius: '10px', backdropFilter: 'blur(2px)' }}
                  />
                </LocalizationProvider>
                <div className="slider-container">
                  <label>Price Range</label>
                  <Slider
                    value={[productData.lower_price_range, productData.higher_price_range]}
                    onChange={handleSliderChange}
                    valueLabelDisplay="auto"
                    min={0}
                    max={1000}
                    marks={marks}
                    style={{ borderRadius: '10px', backdropFilter: 'blur(2px)' }}
                  />
                </div>
                <Autocomplete
                  multiple
                  id="tags-filled"
                  options={filteredTags.map(tag => tag.tag)}
                  value={formData.tagNames} // Set the value to the current tags
                  onChange={handleTagInputChange}
                  inputValue={inputValue}
                  onInputChange={handleInputChange}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={index}
                        label={option}
                        {...getTagProps({ index })}
                        style={{ backgroundColor: '#f0f0f0', marginRight: 5 }}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Trade Wishlist's Tags"
                      margin="normal"
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        style: { borderRadius: '10px', backdropFilter: 'blur(2px)' },
                      }}
                    />
                  )}
                  style={{ maxWidth: '450px' }}
                />
                <div className="location-container">
                  <Autocomplete
                    freeSolo
                    options={locationOptions}
                    getOptionLabel={(option) => option.display_name}
                    onInputChange={(event, newInputValue) => {
                      setLocationLabel("Search Location");
                      handleLocationSearch(newInputValue);
                    }}
                    onChange={handleLocationSelect}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={locationLabel}
                        fullWidth
                        margin="normal"
                        style={{ borderRadius: '10px', backdropFilter: 'blur(2px)' }}
                      />
                    )}
                  />
                  <Button
                    variant="contained"
                    onClick={handleLocateMe}
                    style={{ marginTop: '10px', borderRadius: '10px' }}
                  >
                    Locate Me
                  </Button>
                </div>
              </form>
            </div>
          </div>
          <div className='submit-button'>
            <Button
              type="submit"
              variant="contained"
              style={{
                marginTop: '20px',
                borderRadius: '40.5px',
                background: '#10374C',
                color: '#FFF',
                width: '300px'
              }}
              fullWidth
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </div>}
    </>
  );
};

export default EditProduct;
