import React from 'react';
import './MessageTile.css';
import { GiCardExchange } from 'react-icons/gi';

const MessageTile = ({ message, handleSelectMessage }) => {


    const getMessageContent = () => {
        if (message.can_chat) {
            return message.trade_message; //? `You: ${message.trade_message}` : `${message.senderName}: ${message.trade_message}`;
        } else if (message.can_approve) {
            return message.trade_message;
        } else if (message.can_cancel) {
            return message.trade_message;
        }
        return '';
    };

    return (
        <div key={message.trade_id} className="message-item" onClick={() => handleSelectMessage(message)}>
          <div className="left-chat-tile-section">
          <div className="profile-container">
            <img src={message.user_profile_picture} alt={message.user_name} className="sender-image" />
            <div className={`active-icon ${true ? 'active' : 'inactive'}`}></div>
        </div>
            <div>
                <div className="sender-name">{message.user_name}</div>
                <div className="latest-message" style={{fontWeight:(message.isRead?"":"bold")}}>{getMessageContent() || message.trade_message}</div>
            </div>
          </div>
          <div className='right-chat-tile-section'>
            <div className='last-activity'>{message.last_action_time || '10 minutes ago'}</div>
            <div className="message-images">
            {message.trade_product_names} <span className="arrow-icon"> ↔ </span> {message.user_product_names}
            </div>
          </div>
        </div>
    );
};

export default MessageTile;
