import React, { useState, useEffect } from 'react';
import Pusher from 'pusher-js';
import { GiCardExchange } from 'react-icons/gi'; // Import exchange icon
import './ChatWindow.css'; // Import your CSS file for styling
import { IoIosArrowBack } from "react-icons/io";

const ChatWindow = ({ selectedMessage, handleBack }) => {
  const photos1 = selectedMessage.photoItems1;
  const photos2 = selectedMessage.photoItems2;

  const [textMessage, setTextMessage] = useState('');
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    // Initialize Pusher
    const pusher = new Pusher('your-pusher-key', {
      cluster: 'your-cluster',
    });

    const channel = pusher.subscribe('chat');
    channel.bind('message', function(data) {
      setMessages(prevMessages => [...prevMessages, data]);
    });

    // Cleanup on unmount
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, []);

  const handleMessageChange = (e) => {
    setTextMessage(e.target.value);
  };

  const handleSendMessage = async () => {
    if (textMessage.trim() !== '') {
      const newMessage = {
        sender: 'You',
        content: textMessage.trim(),
        trade_id: selectedMessage.trade_id, // Assuming this is how you identify the trade/chat
      };

      // Send message to backend
      await fetch('http://xxxxxxx/api/postMessage', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newMessage),
      });

      setTextMessage('');
    }
  };

  return (
    <div className="chat-window">
      <div className="left-section">
        <div className="chat-header">
          <IoIosArrowBack onClick={handleBack} className="back-button" />
          <div className="sender-info">
            <img src={selectedMessage.sender.image} alt={selectedMessage.sender.name} className="sender-image" />
            <div>
              <div className="sender-name">{selectedMessage.sender.name}</div>
              <div className="product-name">{selectedMessage.productName}</div>
            </div>
          </div>
        </div>
        <div className="message-container">
          {messages.map((message, index) => (
            <div key={index} className={`message ${message.sender === 'You' ? 'sent' : 'received'}`}>
              <div className="message-content">{message.content}</div>
            </div>
          ))}
        </div>
        <div className="input-container">
          <input
            type="text"
            placeholder="Type a message..."
            value={textMessage}
            onChange={handleMessageChange}
            className="text-input"
          />
          <button onClick={handleSendMessage} className="send-button">Send</button>
        </div>
      </div>
      <div className='right-section'>
        <div className="photo-content">
          {photos1.length > 1 ? (
            <div className="stacked-photos-sent">
              {photos1.map((photo, index) => (
                <img key={index} src={photo} alt={`Photo ${index + 1}`} className="photo" />
              ))}
            </div>
          ) : (
            <img src={photos1[0]} alt="Photo" className="stacked-photos-sent" />
          )}
          <GiCardExchange className="exchange-icon" />
          {photos2.length > 1 ? (
            <div className="stacked-photos-received">
              {photos2.map((photo, index) => (
                <img key={index} src={photo} alt={`Photo ${index + 1}`} className="photo" />
              ))}
            </div>
          ) : (
            <img src={photos2[0]} alt="Photo" className="stacked-photos-received" />
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatWindow;
