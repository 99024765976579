import React,{useEffect, useState} from 'react';
import './Notifications.css'; // Import your CSS file for styling
import axios from 'axios';
import Loading from '../Loading/Loading';

function Notification() {

  const messages = [
    {
      sender: {
        notification: 'Cameron Williamsonl Requested for trading with i Phone & Camera',
        image: 'https://pxbar.com/wp-content/uploads/2023/09/black-dp-boy.jpg', // Sample image URL
        active: true,
      },  
    },
    {
      sender: {
        notification: 'Stella Angel accepted your Trade request with Air pod',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQzHMDlwRCHOHZP_tX7jRYNxV8W8MpNEog45w&s', // Sample image URL
        active: false,
      },
    },
    // Add more sample messages as needed
  ];

  const [notification,setNotification] = useState(null)
  const [isLoading,setIsLoading] = useState(true)
  useEffect(()=>{
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: 'https://backend.monkeytrade.xyz/api/notifications',
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem("bearerToken")}`
      }
    };
    axios.request(config)
    .then((response) => {
        setNotification(response.data.data)
        setIsLoading(false)
    })
    .catch((error) => {
      console.log(error);
    });
  },[])

  return (
    <div>
      {isLoading&&<Loading/>}
      <div className="notification-container">
      <h2>Notifications</h2>
      {notification!=null &&notification.map((message, index) => (
        <div key={index} className="notification-item" onClick={() => {}}>
          <div className="notification-info">
            <img src={message.user_profile_picture} alt={message.title} className="notification-image" />
            <div className="notification-name">{message.title}</div>
          </div>
        </div>
      ))}
    </div>
    </div>
  );
}

export default Notification;