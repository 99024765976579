import React, { useState , useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/Login/Login';
import Home from './components/Home/Home';
import Messages from './components/Messages/Messages';
import Notification from './components/Notifications/Notifications';
import Create from './components/Create/Create';
import Trade from './components/Trade/Trade';
import SideHeader from './components/SideHeader/SideHeader';
import InitialPage from './components/InitialPage';
import AddItem from './components/AddItem/AddItem';
import ProfileHeader from './components/ProfileHeader/ProfileHeader';
import ProductDetails from './components/Home/product';
import './App.css'; 
import TradeRequestPage from './components/Trade/TradeRequestPage/TradeRequestPage';
import TradeWindow from './components/Trade/TradeWindow/TradeWindow';
import PrivateRoute from './PrivateRoute';
import UserProfileEdit from './components/UserProfileEdit/UserProfileEdit';
import EditProduct from './components/EditProduct/EditProduct';
import Loading from './components/Loading/Loading';


function App() {
  const [loadProfile, setLoadProfile] = useState(0);
  const [loggedIn, setLoggedIn] = useState(false);
  const [activeItem, setActiveItem] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [editProductId, setEditProductId] = useState(null);
  const [profileImage, setProfileImage] = useState('');
  const [isLoading,setIsLoading] = useState(true);
  const [resetHome,setResetHome] = useState(false);

  const validateToken = async (token) => {
    try {
      const response = await fetch('https://backend.monkeytrade.xyz/api/verify_token', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        setProfileImage(data.data.profile_picture)
        setPhoneNumber(data.data.phone)
        return data.status; // Adjust based on your backend response
      } else {
        return false;
      }
    } catch (error) {
      console.error('Error validating token:', error);
      return false;
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('bearerToken');
    if (token) {
      validateToken(token).then(isValid => {
        setLoggedIn(isValid);
        setIsLoading(false);
      });
    }
  }, []);

  const setItemIndex = (num) => {
    setActiveItem(num);
  };

  return (
    <div className='application-root'>
      <Router>
        <Routes>
          <Route path='/' element={<Login setAppIsLoading={setIsLoading} setProfileImage={setProfileImage} setLoggedIn={setLoggedIn} setPhoneNumber={setPhoneNumber} />} />
          <Route path="/create" element={<Create />} />
          <Route
            path="/*"
            element={ isLoading?<Loading/>:
              <PrivateRoute isLoggedIn={loggedIn}>
                <div className="app-container">
                  {/* <div className='side-header'>
                    <SideHeader activeItem={activeItem} setActiveItem={setActiveItem} setResetHome={setResetHome} />
                  </div> */}
                  
                  <div className="main-content">
                  <div className='upperHeader'></div>
                    <ProfileHeader
                      profileImageUrl={profileImage}
                      userName={localStorage.getItem("userName")}
                      phoneNumber={phoneNumber}
                      setLoggedIn={setLoggedIn}
                      loadProfile={loadProfile}
                      setEditProductId={setEditProductId}
                      activeItem={activeItem} setActiveItem={setActiveItem} setResetHome={setResetHome}
                    />
                    <Routes>
                      <Route path="/acc/home" element={<Home setProfileImage={setProfileImage} setItemIndex={setItemIndex} setPhoneNumber={setPhoneNumber} resetHome={resetHome} setResetHome={setResetHome} />} />
                      <Route path="/acc/create" element={<AddItem loadProfile={loadProfile} setLoadProfile={setLoadProfile} setItemIndex={setItemIndex} />} />
                      <Route path="/acc/messages" element={<Messages />} />
                      <Route path="/acc/notification" element={<Notification />} />
                      <Route path="/acc/trade" element={<Trade />} />
                      <Route path="/product/:productId" element={<ProductDetails setItemIndex={setItemIndex}/>} />
                      <Route path="/trade/:tradeId" element={<TradeWindow />} />
                      <Route path="/user-profile/edit" element={<UserProfileEdit />} />
                      <Route path='/product/edit' element={<EditProduct productId={editProductId} />} />
                    </Routes>
                  </div>
                </div>
              </PrivateRoute>
            }
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
