// App.js

import React, { useState, useEffect } from 'react';
import './ChatPage.css';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ChatWindow from '../ChatWindow/ChatWindow';
import Loading from '../../../Loading/Loading';
import axios from 'axios';


function ProductTile({ product, onClick }) {
  return (
    <div className={`product-tile-chat ${product.select ? 'selected' : ''}`} onClick={() => onClick(product.product_id)}>
      <div className="product-title-chat">{product.title}</div>
      <div className="product-description-chat">{product.sub_title}</div>
      <div
        className="product-image-chat"
        style={{ background: `url(${product.image_url}) lightgray 50% / cover no-repeat` }}
      >
        <div className="tile-overlay"></div>
      </div>
    </div>
  );
}

function TradeSection({ profileImage, products, toggleSelect,name }) {
  return (
    <div className="section-chat">
      <div
        className="profile-image-chat"
        style={{ background: `url(${profileImage}) lightgray 50% / cover no-repeat` }}
      ></div>
      <button className='match-button-trade-chat'>{name}</button>
      <div className="products-chat">
        {products.map((product) => (
          <ProductTile key={product.product_id} product={product} onClick={toggleSelect} />
        ))}
      </div>
    </div>
  );
}

function ChatPage({setMessageList,setChatWindow , selectedMessage}) {

  const [tradeDetails,setTradeDetails] = useState(selectedMessage);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isChatEnable,setIsChatEnable] = useState();

  useEffect(()=>{
    

      tradeDetails.current_user_trade_data.product_details.forEach(product => {
        if (product.select && !selectedProducts.includes(product.product_id)) {
          selectedProducts.push(product.product_id);
        }
      });
        tradeDetails.other_user_trade_data.product_details.forEach(product => {
        if (product.select  && !selectedProducts.includes(product.product_id)) {
          selectedProducts.push(product.product_id);
        }
      });
      setIsChatEnable(true);
      setIsLoading(false);
  },[])

  const updateSelectedProducts = (id)=>{
    if(selectedProducts.includes(id)){
      setSelectedProducts(selectedProducts.filter(productId => id !== productId)); 
    } else {
      setSelectedProducts([...selectedProducts, id]);
    }
  }

  const [isLoading,setIsLoading] = useState(true);

  const toggleSelect = (id) => {
    updateSelectedProducts(id);
    setTradeDetails((prevData) => {
      const updateSelection = (products) =>
        products.map((product) =>
          product.product_id === id ? { ...product, select: !product.select } : product
        );

      return {
        current_user_trade_data: {
          ...prevData.current_user_trade_data,
          product_details: updateSelection(prevData.current_user_trade_data.product_details),
        },
        other_user_trade_data: {
          ...prevData.other_user_trade_data,
          product_details: updateSelection(prevData.other_user_trade_data.product_details),
        },
      };
    });
  };


  return (
    isLoading?<Loading/>:
    <div>
    <div className="container-trade-chat">
    <TradeSection
        profileImage={tradeDetails.other_user_trade_data.user_details.profile_picture}
        products={tradeDetails.other_user_trade_data.product_details}
        toggleSelect={toggleSelect}
        name={tradeDetails.other_user_trade_data.user_details.name}
      />
      <TradeSection
        profileImage={tradeDetails.current_user_trade_data.user_details.profile_picture}
        products={tradeDetails.current_user_trade_data.product_details}
        toggleSelect={toggleSelect}
        name={tradeDetails.current_user_trade_data.user_details.name}
      />
      <div className='section-chat-window'>
        <ChatWindow  tradeId={tradeDetails.trade_id} userName={tradeDetails.other_user_trade_data.user_details.name} />
      </div>
    </div>
    </div>
  );
}

export default ChatPage;
