// Popup.js

import React from 'react';
import './Popup.css';

const Popup = ({ handleClose, handleFileUpload }) => {
  return (
    <div className="popup">
      <div className="popup-arrow"></div>
      <div className="popup-content">
        <input type="file" accept="image/*, video/*" onChange={handleFileUpload} />
      </div>
    </div>
  );
};

export default Popup;
