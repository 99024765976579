import React, { useState, useEffect, useRef } from 'react';
import SendIcon from '@material-ui/icons/Send';
import MicIcon from '@material-ui/icons/Mic';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Popup from './Popup';
import './ChatWindow.css';

const ChatWindow = ({ tradeId, userName }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const [latestMessageTime, setLatestMessageTime] = useState(null);
  const [messageIds, setMessageIds] = useState(new Set());
  const limit = 20;
  const messagesEndRef = useRef(null);
  const messagesContainerRef = useRef(null);

  useEffect(() => {
    // Initial load
    fetchMessages(offset, limit, true);

    // Polling for new messages
    const interval = setInterval(() => {
      fetchMessages(0, limit, false);
    }, 5000); // Adjust the interval as needed

    return () => clearInterval(interval);
  }, []);

  const fetchMessages = async (offset, limit, isInitialLoad) => {
    try {
      const response = await fetch(`https://backend.monkeytrade.xyz/api/messages?trade_id=${tradeId}&limit=${limit}&offset=${offset}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('bearerToken')}`
        }
      });
      const data = await response.json();
      setMessages(data.reverse());
      // if (isInitialLoad) {
      //   setMessages(data);
      //   if (data.length > 0) {
      //     setLatestMessageTime(new Date(data[data.length - 1].date_time));
      //     setMessageIds(new Set(data.map(message => message.date_time)));
      //   }
      // } else {
      //   // Filter new messages based on the latestMessageTime
      //   const newMessages = data.filter(message => !messageIds.has(message.date_time));
      //   if (newMessages.length > 0) {
      //     setMessages(prevMessages => [...prevMessages, ...newMessages]);
      //     setLatestMessageTime(new Date(newMessages[newMessages.length - 1].date_time));
      //     setMessageIds(prevIds => {
      //       const newIds = new Set(prevIds);
      //       newMessages.forEach(message => newIds.add(message.date_time));
      //       return newIds;
      //     });
      //   }
      // }
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  const sendMessage = async () => {
    if (newMessage.trim() !== '') {
      await fetch('https://backend.monkeytrade.xyz/api/postMessage', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('bearerToken')}`
        },
        body: JSON.stringify({
          message: newMessage,
          trade_id: tradeId
        })
      });
      setNewMessage('');
      fetchMessages(0, limit, false);
    }
  };

  const handleFileUpload = (event) => {
    console.log(event.target.files);
    setIsPopupOpen(false);
  };

  const handleScroll = () => {
    if (messagesContainerRef.current.scrollTop === 0 && !loading) {
      setLoading(true);
      setOffset(prevOffset => prevOffset + limit);
      fetchMessages(offset + limit, limit, true).then(() => {
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    const container = messagesContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [loading]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  return (
    <div className="trade-chat-chat-window">
      <div className="trade-chat-header">
        <div className="trade-chat-sender-name">{userName}</div>
        <div className="trade-chat-active-indicator"></div>
      </div>

      <div className="trade-chat-messages" ref={messagesContainerRef}>
        {messages.map((message, index) => (
          <div
            key={index}
            className={`trade-chat-message ${!message.received_message ? 'trade-chat-sent' : 'trade-chat-received'}`}
          >
            <div className="trade-chat-bubble">{message.message}</div>
          </div>
        ))}
        <div ref={messagesEndRef}></div>
      </div>

      <div className="trade-chat-input-container">
        <div className="icon-container" onClick={() => setIsPopupOpen(!isPopupOpen)}>
          <AttachFileIcon />
          {isPopupOpen && (
            <Popup handleClose={() => setIsPopupOpen(false)} handleFileUpload={handleFileUpload} />
          )}
        </div>
        <button className="trade-chat-voice-record-btn">
          <MicIcon />
        </button>
        <textarea
          className="trade-chat-message-input"
          placeholder="Type your message..."
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
        />
        <button className="trade-chat-send-btn" onClick={sendMessage}>
          <SendIcon />
        </button>
      </div>
    </div>
  );
};

export default ChatWindow;
