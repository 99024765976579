import React from 'react';
import './Popup.css'; // Import CSS file for styling

function Popup({ show, onClose, onHomeClick, onTradeClick }) {
  // Render nothing if show is false
  if (!show) {
    return null;
  }

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        {/* <button className="close-button" onClick={onClose}>X</button> */}
        <div className="popup-body">
          <h2>Trade Completed!</h2>
          <div className="tick-icon">&#10003;</div>
          <div className="buttons-container">
            <button className="button" onClick={onClose}>VIEW</button>
            {/* <button className="button" onClick={onTradeClick}>Trade</button> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Popup;
