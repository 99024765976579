import {React,useState} from 'react';
import './Messages.css'; // Import your CSS file for styling
import { GiCardExchange } from "react-icons/gi";
import ChatWindow from './ChatWindow';
const Messages = () => {
  const messages = [
    {
      sender: {
        name: 'John Doe',
        image: 'https://pxbar.com/wp-content/uploads/2023/09/black-dp-boy.jpg', // Sample image URL
        active: true,
      },
      open:true,
      productName:"ps5 2020 Model",
      photoItems1:["https://gmedia.playstation.com/is/image/SIEPDC/ps5-product-thumbnail-01-en-14sep21?$facebook$"],
      photoItems2:["https://imgd.aeplcdn.com/642x336/n/cw/ec/102709/ntorq-125-right-front-three-quarter.jpeg?isig=0&q=80"],
      latestMessage: "I'm good, thanks!",
      images: ['https://gmedia.playstation.com/is/image/SIEPDC/ps5-product-thumbnail-01-en-14sep21?$facebook$', 'https://imgd.aeplcdn.com/642x336/n/cw/ec/102709/ntorq-125-right-front-three-quarter.jpeg?isig=0&q=80'], // Sample image URLs
    },
    {
      sender: {
        name: 'Anna Urban',
        image: 'https://mastimorning.com/wp-content/uploads/2023/07/Best-HD-girls-whatsapp-dp-Wallpaper.jpg', // Sample image URL
        active: false,
      },
      photoItems1:["https://iplanet.one/cdn/shop/files/iPhone_14_Blue_PDP_Image_Position-1A__WWEN_823x.jpg?v=1691142210","https://www.digitaltrends.com/wp-content/uploads/2022/09/iPhone-14-Pro-Back-Purple-Hand.jpg?p=1"],
      photoItems2:["https://imgd.aeplcdn.com/642x336/n/cw/ec/102709/ntorq-125-right-front-three-quarter.jpeg?isig=0&q=80","https://www.drivespark.com/bikes-photos/models/450x350/hondadio_1666954779.jpg/3/x.pagespeed.ic.wFlihr-Xs1.jpg"],
      latestMessage: ' Is this still available?',
      images: ['https://iplanet.one/cdn/shop/files/iPhone_14_Blue_PDP_Image_Position-1A__WWEN_823x.jpg?v=1691142210', 'https://imgd.aeplcdn.com/642x336/n/cw/ec/102709/ntorq-125-right-front-three-quarter.jpeg?isig=0&q=80'], // Sample image URLs
    },
    // Add more sample messages as needed
  ];

  const [selectedMessage, setSelectedMessage] = useState(null);

  // Function to handle selection of a message
  const handleSelectMessage = (message) => {
    message.open=false
    setSelectedMessage(message);
  };

  // Function to handle going back to the message list page
  const handleBack = () => {
    setSelectedMessage(null);
  };

  return (
    <>
    {selectedMessage ? (
        <ChatWindow selectedMessage={selectedMessage} handleBack={handleBack} />
      ) : (
    <div className="messages-container">
      {messages.map((message, index) => (
        <div key={index} className="message-item" onClick={() => handleSelectMessage(message)}>
          <div className="sender-info">
            <div className={`active-icon ${message.sender.active ? 'active' : 'inactive'}`}></div>
            <img src={message.sender.image} alt={message.sender.name} className="sender-image" />
            <div className="sender-name">{message.sender.name}</div>
            <div className="latest-message" style={{fontWeight:(message.open?"bold":"")}}>{message.latestMessage}</div>
          </div>
          <div className="message-images">
            <img src={message.images[0]} alt="Image 1" className="message-image" />
            <div className="exchange-icon"><GiCardExchange /></div>
            <img src={message.images[1]} alt="Image 2" className="message-image" />

          </div>
        </div>
      ))}
    </div>)}
    </>
  );
};


export default Messages;